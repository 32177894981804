import "./global.css";
import logo from "./asset/logo.svg";
import hamburgerMenu from "./asset/hamburgerMenu.svg";
import headingScatck from "./asset/headingSkatch.svg";
import transporterFlow from "./asset/transporterFlow.png";
import transporterFlow1000 from "./asset/transporterFlow_1000.png";

import heartIcon from "./asset/heartIcon.svg";
import benefitArrow from "./asset/benefitArrow.svg";
import Personalised from "./asset/personalisedIcon.svg";

import communicationIcon from "./asset/communicationIcon.svg";
import conversationIcon from "./asset/conversationIcon.svg";
import reduceCostIcon from "./asset/reduceCostIcon.svg";
import star from "./asset/scaleWithEazeStar.svg";

import bgFrame from "./asset/bgFarme.svg";
import dashboardImage from "./asset/dashboardImage.png";
import scaleWithEazeBg from "./asset/scaleWithEazeBg.png";

import sourceOneLogo from "./asset/sourceone-logo.svg";
import trucksImage from "./asset/trucksImage.png";

import dasboardImage1 from "./asset/dasboardImage1.png";
import dasboardImage2 from "./asset/dasboardImage2.png";
import dasboardImage3 from "./asset/dasboardImage3.png";

import viewComplete360 from "./asset/360ViewComplete.svg";
import negotiate from "./asset/negotiate.svg";
import steamlined from "./asset/steamlined.svg";

import rightArrow from "./asset/rightButtonArrow.svg";
import playButton from "./asset/playButton.svg";
import truckBackground from "./asset/truck_backgroud.png";

import money from "./asset/money.svg";
import circle from "./asset/circleIcon.svg";
import close from "./asset/closeCross.svg";
import { useState } from "react";

const zohoFormUrl =
  "https://forms.zohopublic.in/source/form/DemoRequestseasybid/formperma/Xg-aTq7nPeq_IqZXZiLOWs-b-03KkDsUGrYETNczt24";

const problemSolve = [
  "Limited Procurement Options",
  "Inefficient Freight Booking",
  "Inefficient Route Planning",
  "Fragmented Communication",
  "Lack of Cost Transparency",
];

const businessHeight = [
  {
    title: "Better Communication",
    value: `We have implemented Whatsapp Business API in our SAAS platform for efficient, real-time and convenient stakeholders communication.`,
    icon: reduceCostIcon,
  },
  {
    title: "Reduce Cost by 20%",
    value: `We developed highly-optimised design processes to let us work fast and deliver incredible results and we pass those savings on to you`,
    icon: communicationIcon,
  },
  {
    title: "Convenience",
    value: `Our system enables users to compare quotes from multiple transporters with convenience, resulting in providing best possible freights.`,
    icon: conversationIcon,
  },
];

const PersonalisedList = [
  {
    title: "Get Best Freight for your Transport",
    value: `Optimize transportation with eazy.bid's top-notch freight solutions.`,
    icon: money,
    className: "md:absolute md:top-[110px] md:max-w-[150px]",
    rightSide: false,
  },
  {
    title: "Get 360° view of the Process",
    value: `Visualize your logistics journey like never before – from start to finish.`,
    icon: viewComplete360,
    className: "md:absolute md:top-[300px] md:max-w-[150px]",
    rightSide: false,
  },
  {
    title: "Easily Communicate with Transporter",
    value: `Accelerate freight approvals with eazy.bid's efficient communication system.`,
    icon: steamlined,
    className:
      "lg:absolute lg:top-[260px] right-0 lg:max-w-[150px] lg:text-right",
    rightSide: true,
  },
  {
    title: "Easily Negotiate Freights with Bidder",
    value: `Effortlessly negotiate freights with our intuitive bidder interface.`,
    icon: negotiate,
    className:
      "lg:absolute lg:top-[490px] right-0 lg:max-w-[150px] lg:text-right",
    rightSide: true,
  },
];

const triedTested = [
  { title: "40+", value: "Satisfied Customers" },
  { title: "1000+", value: "Daily Quotes are Received" },
  { title: "700+", value: "Active Transporters" },
];

function App() {
  return (
    //sm:bg-red-600 md:bg-blue-500 lg:bg-green-400
    <main className="bg-[#FBF6F1] h-full">
      <HeroSection />
      <ProcessFlow />
      <Technology />
      <Benefits />
      <PersonalisedDashboard />
      <TriedAndTested />
      <SizeWithEaze />
      <Footer />
    </main>
  );
}

const HeroSection = () => {
  const [menu, setMenu] = useState(false);
  return (
    <section className="bg-white" id="homePage">
      <header className={`flex justify-between items-center p-4 maxContainer`}>
        <img src={logo} alt="logo" />
        <nav className="uppercase lg:min-w-[700px] justify-evenly hidden lg:flex">
          <div className="text-indigo-600">
            <a href="#homePage">home</a>
          </div>
          <div>
            <a href="#howItWorks">how it works</a>
          </div>
          <div>
            <a href="#interface">Interface</a>
          </div>
          <div>
            <a href="#benefits">Benefits</a>
          </div>
          <div>
            <a href="#insights">Insights</a>
          </div>
        </nav>
        <button className="lg:hidden" onClick={() => setMenu((p) => !p)}>
          <img src={hamburgerMenu} alt="logo" />
        </button>
        <button className="hidden lg:block h-[46px] px-[18px] py-3.5 rounded-lg shadow border border-gray-600 justify-center items-center gap-[7.20px]">
          <a href={zohoFormUrl} target="_blank" rel="noreferrer">
            <div className="text-center text-black text-sm font-normal font-['Product Sans'] leading-[17.28px]">
              Book a Demo
            </div>
          </a>
        </button>
      </header>
      {menu && (
        <header
          className={`fixed top-0 right-0 bottom-0 left-0 bg-[#FBF6F1] z-10 md:maxContainer min-h-[80px] p-4 flex justify-between`}
        >
          <img src={logo} alt="logo" className="fixed top-8 left-5" />
          <nav className="uppercase min-h-[400px] w-full grid items-center justify-center text-center text-2xl">
            <div className="flex flex-col gap-8">
              <div className="text-indigo-600" onClick={() => setMenu(false)}>
                <a href="#homePage">home</a>
              </div>
              <div>
                <a href="#howItWorks" onClick={() => setMenu(false)}>
                  how it works
                </a>
              </div>
              <div>
                <a href="#interface" onClick={() => setMenu(false)}>
                  Interface
                </a>
              </div>
              <div>
                <a href="#benefits" onClick={() => setMenu(false)}>
                  Benefits
                </a>
              </div>
              <div>
                <a href="#insights" onClick={() => setMenu(false)}>
                  Insights
                </a>
              </div>
            </div>
          </nav>
          <button
            onClick={() => setMenu((p) => !p)}
            className="fixed top-[34px] right-7"
          >
            <img src={close} alt="logo" className="max-w-[25px]" />
          </button>
        </header>
      )}
      <div className="min-h-[500px] flex justify-center items-center">
        <div className="flex flex-col justify-evenly items-center h-4/5">
          <h1 className="text-4xl md:text-5xl font-medium font-['Gabarito'] text-center text-black leading-snug max-w-[23ch] md:max-w-[30ch]">
            Create your Own{" "}
            <span className="relative">
              Marketplace
              <img
                src={headingScatck}
                alt="logo"
                className="absolute top-10 left-0 m-auto md:top-[50px] md:left-10"
              />
            </span>
            <br /> for Freight Bidding
          </h1>
          <p className="text-center text-black text-opacity-70 text-sm font-normal max-w-[300px] md:text-base md:max-w-[500px] font-['Product Sans'] leading-snug tracking-tight my-10">
            Streamline your goods transportation process and unlock unbeatable
            savings with our cutting-edge SAAS platform.
          </p>
          <div className="flex gap-5">
            <a href={zohoFormUrl} target="_blank" rel="noreferrer">
              <button className="w-[160px] h-[46px] px-[18px] py-3.5 bg-indigo-600 rounded-lg shadow border border-gray-600 justify-center items-center gap-[7.20px] inline-flex">
                <div className="text-center text-gray-100 text-sm font-normal font-['Product Sans'] leading-[17.28px]">
                  Book a Demo
                </div>
                <img src={rightArrow} alt="logo" />
              </button>
            </a>
            <a href="#howItWorks">
              <button className="w-[160px] h-[46px] px-[18px] py-3.5 rounded-lg shadow border border-gray-600 justify-center items-center gap-[7.20px] inline-flex">
                <img src={playButton} alt="logo" />
                <div className="text-center text-black text-sm font-normal font-['Product Sans'] leading-[17.28px]">
                  How it works
                </div>
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

const ProcessFlow = () => {
  return (
    <div className="maxContainer py-16" id="howItWorks">
      <section className="min-h-[500px] mb-20">
        <div className=" text-center text-indigo-600 text-sm mb-3 font-bold font-['Product Sans'] uppercase">
          PROCESS FLOW
        </div>
        <div className=" text-center text-black text-[26px] font-medium font-['Gabarito'] leading-[33px]">
          The technology behind eazy.
          <span className="  text-indigo-600 ">bid</span>
        </div>
        <br />
        <br />
        <div className="flex justify-center mx-2">
          <picture>
            <source srcset={transporterFlow1000} media="(min-width: 850px)" />
            <source srcset={transporterFlow} />
            <img src={transporterFlow} alt="Flowers" />
          </picture>
        </div>
      </section>
      <section>
        <div className="text-center mb-7">
          <span className="text-zinc-900 text-xl font-bold font-['Product Sans'] leading-10">
            What eazy.
            <span className="text-indigo-600 font-bold font-['Product Sans'] leading-10 relative">
              bid
            </span>{" "}
            solves for you
          </span>
        </div>

        <div className="flex flex-wrap gap-4 md:gap-9 justify-center">
          {problemSolve.map((x) => (
            <div
              className="w-[99.72px] sm:w-[155px] h-[50px] relative bg-red-300 bg-opacity-25 rounded border border-black border-opacity-20 flex items-center"
              key={x}
            >
              <div className="text-center text-black text-[11px] sm:text-sm font-normal font-['Product Sans'] leading-none">
                {x}
              </div>
              {/* <div className="w-[117.94px] sm:w-[185px] h-[0px] left-[-10.33px] top-[42.88px] absolute origin-top-left rotate-[-17.66deg] sm:rotate-[-12.66deg] border border-rose-300 opacity-60"></div> */}
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

const Technology = () => {
  return (
    <section className="py-10 px-3 maxContainer" id="interface">
      <h2 className="text-black text-[26px] font-semibold font-['Gabarito'] leading-[33px]">
        <span className="mr-3">Integrating Procurement with Technology</span>
        <img src={heartIcon} alt="logo" className="inline-block" />
      </h2>
      <br />
      <div className=" text-black text-opacity-70 text-sm font-normal font-['Product Sans'] leading-[18px]">
        Your business has gone online. Why haven’t your goods procurement?
      </div>
      <br />
      <br />
      <div class="grid gap-6 col-start-1 col-end-3 row-start-1 lg:grid-cols-4 lg:gap-6 lg:col-start-2 lg:row-end-6 lg:row-span-6">
        <div class="grid lg:grid-cols-[450px_1fr] w-full h-[450px] object-cover lg:col-span-full pt-6 bg-white rounded-[18px] shadow relative overflow-hidden">
          <div className="px-4 lg:pt-5">
            <div className="text-neutral-700 text-xl font-bold font-['Product Sans'] mb-3 ">
              Get a detailed view of each transporter profile and order details
            </div>

            <div className="text-black text-opacity-60 text-sm font-normal font-['Product Sans'] leading-normal">
              Bid farewell to the hassle of searching for transporters and
              negotiating freight costs. With EazyBid reach to 1000+
              transporters across India and choose according to your
              requirements
            </div>
          </div>
          <div className="overflow-hidden">
            <img
              src={dasboardImage3}
              alt="logo"
              className="min-w-[600px] md:min-w-[800px] sm:ml-5"
            />
          </div>
        </div>
        <div class="flex flex-col justify-between w-full h-[450px] object-cover lg:row-start-2 lg:col-span-2 pt-6 bg-white rounded-[18px] shadow relative overflow-hidden">
          <div className="px-4">
            <div className="text-neutral-700 text-xl font-bold font-['Product Sans']">
              Seamless Communication, Maximum Control
            </div>
            <div className="text-black text-opacity-60 text-sm font-normal font-['Product Sans'] leading-normal">
              Our integrated webchat module empowers you to coordinate,
              negotiate, and collaborate effortlessly with bidders through
              Whatsapp.
            </div>
          </div>
          <div>
            <img
              src={dasboardImage2}
              alt="logo"
              className="min-w-[600px] sm:ml-5"
            />
          </div>
        </div>
        <div class="flex flex-col justify-between w-full h-[450px] object-cover lg:block lg:row-start-2 lg:col-span-2 pt-6 bg-white rounded-[18px] shadow relative overflow-hidden">
          <div className="px-4">
            <div className="text-neutral-700 text-xl font-bold font-['Product Sans']">
              Simplify Freight Decisions with Confidence
            </div>
            <div className="text-black text-opacity-60 text-sm font-normal font-['Product Sans'] leading-normal">
              Easily compare quoted freights from multiple bidders and place
              reverse quotes, ensuring you secure the best deal. The Confirm
              Transport modal empowers user for cost-effective logistics.
            </div>
          </div>
          <div>
            <img
              src={dasboardImage1}
              alt="logo"
              className="min-w-[600px] sm:ml-5"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

const Benefits = () => (
  <section className="py-10 mb-10 md:py-24 maxContainer" id="benefits">
    <div className=" text-center text-indigo-600 text-sm mb-3 font-bold font-['Product Sans'] uppercase">
      BENEFITS
    </div>
    <div className=" text-center text-black text-[26px] font-medium font-['Gabarito'] leading-[33px] flex justify-center">
      <p className="max-w-[25ch] md:max-w-[50ch] mb-7">
        Take your Business to New
        <span className="mr-3"> Heights</span>
        <img src={benefitArrow} alt="logo" className="inline-block" />
      </p>
    </div>
    <br />
    <br />

    <div
      className="grid gap-10 items-center"
      style={{ gridTemplateColumns: "repeat( auto-fit, minmax(250px, 1fr) )" }}
    >
      {businessHeight.map(({ title, value, icon }) => (
        <div className="flex flex-col items-center gap-3 justify-between">
          <div>
            <img src={icon} alt="logo" />
          </div>
          <div className="max-w-[20rem] text-center text-black text-xl font-bold font-['Product Sans']">
            {title}
          </div>
          <div className="max-w-[20rem] text-center text-black text-opacity-70 text-sm font-normal font-['Product Sans'] leading-snug">
            {value}
          </div>
        </div>
      ))}
    </div>
  </section>
);

const PersonalisedDashboard = () => (
  <section
    className="min-h-[600px] bg-white py-28"
    style={{ backgroundImage: `url(${bgFrame})` }}
  >
    <div className="maxContainer relative px-3">
      <div className=" text-center text-indigo-600 text-sm mb-3 font-bold font-['Product Sans'] uppercase">
        1,00,000 KM QUOTED AND COUNTing
      </div>
      <div className=" text-center text-black text-[26px] font-medium font-['Gabarito'] leading-[33px]">
        Transform your Logistics Management with a
        <br />
        <span span className="relative">
          Personalised
          <img
            src={Personalised}
            alt="logo"
            className="absolute top-8 left-0 m-auto"
          />
        </span>{" "}
        Dashboard
      </div>
      <img
        src={dashboardImage}
        alt="logo"
        className="aspect-video lg:max-w-[840px] m-auto"
      />
      <div className="grid grid-cols-2 gap-8 px-3 mt-5">
        {PersonalisedList.map(
          ({ title, value, icon, className, rightSide }) => (
            <div className={`flex flex-col gap-3 ${className} mt-7`}>
              <div className="mb-2">
                <img
                  src={icon}
                  alt="logo"
                  className={rightSide && "lg:ml-auto"}
                />
              </div>
              <div className="max-w-[20rem] text-black text-base font-bold font-['Product Sans'] leading-tight">
                {title}
              </div>
              <div className="max-w-[20rem] text-black text-opacity-50 text-sm font-normal font-['Product Sans'] leading-4">
                {value}
              </div>
            </div>
          )
        )}
      </div>
    </div>
  </section>
);

const TriedAndTested = () => (
  <section className="py-16 px-3 maxContainer" id="insights">
    <div className=" text-center text-black text-[23px] font-medium font-['Gabarito'] leading-[33px]">
      Tried & Tested by
      <br />
      India’s
      <span className="relative p-1">
        {" "}
        Largest
        <img
          src={circle}
          alt="logo"
          className="absolute m-auto top-[-5px] right-0"
        />
      </span>{" "}
      Polymer Distribution Company
    </div>
    <div className="flex justify-center md:mb-7">
      <img src={sourceOneLogo} alt="logo" className="aspect-video" />
    </div>
    <div className="grid md:grid-cols-[1fr_1fr] gap-10 md:items-center lg:gap-20">
      <div className="">
        <img
          src={trucksImage}
          alt="logo"
          className="aspect-video rounded-lg w-full"
        />
      </div>
      <div className=" text-black text-opacity-60 text-base font-normal font-['Product Sans'] leading-[21px]">
        Source.One is a tech-driven powerhouse transforming the petrochemical
        distribution industry in India. Through a technology-first and
        democratic approach, we’re bridging the demand-supply gap between SMEs
        and suppliers.
        <br />
        <br />
        We stand as a beacon for those in need of petrochemical raw materials
        and those grappling with excess inventory. Our expansive PAN-India
        presence serves to connect businesses across the nation.
      </div>
    </div>
    <br />
    <br />
    <div className="grid gap-10 grid-cols-[repeat(auto-fit,minmax(350px,1fr))] md:grid-cols-[repeat(3,1fr)] md:py-10">
      {triedTested.map(({ title, value }) => (
        <div>
          <div className="text-center text-black text-5xl font-bold font-['Product Sans'] leading-[70px]">
            {title}
          </div>
          <div className="text-center text-black text-opacity-70 text-base font-normal font-['Product Sans'] tracking-tight">
            {value}
          </div>
        </div>
      ))}
    </div>
  </section>
);

const SizeWithEaze = () => (
  <div className="bg-white">
    <section
      className="maxContainer min-h-[300px] bg-white bg-center bg-cover grid sm:grid-cols-2 items-center  sm:flex-row gap-2 sm:p-6 overflow-hidden md:px-10"
      style={{
        backgroundImage: `url(${scaleWithEazeBg})`,
      }}
    >
      <div>
        <div className="text-center sm:text-left mb-3">
          <span className="text-zinc-900 text-[26px] sm:text-3xl font-bold font-['Product Sans'] leading-10">
            Book freights with{" "}
          </span>
          <span className="text-indigo-600 text-[26px] sm:text-3xl font-bold font-['Product Sans'] leading-10 relative">
            eaze
            <img
              src={star}
              alt="logo"
              className="absolute bottom-4 left-14 m-auto"
            />
          </span>
        </div>
        <div className="text-center sm:text-left text-black text-opacity-70 text-base font-normal font-['Product Sans'] leading-[18px]">
          Grab control of your freight, compare multiple
          <br />
          bids with confidence, and unlock savings.
        </div>
        <br />
        <div className="flex gap-5 justify-center sm:justify-normal">
          <a href={zohoFormUrl} target="_blank" rel="noreferrer">
            <button className="w-[160px] h-[46px] px-[18px] py-3.5 bg-indigo-600 rounded-lg shadow border border-gray-600 justify-center items-center gap-[7.20px] inline-flex">
              <div className="text-center text-gray-100 text-sm font-normal font-['Product Sans'] leading-[17.28px]">
                Book a Demo
              </div>
              <img src={rightArrow} alt="logo" />
            </button>
          </a>
          {/* <button className="w-[137.48px] h-[46px] px-[18px] py-3.5 rounded-lg shadow border border-gray-600 justify-center items-center gap-[7.20px] inline-flex">
            <div className="text-center text-black text-sm font-normal font-['Product Sans'] leading-[17.28px]">
              Talk to Sales
            </div>
          </button> */}
        </div>
      </div>
      <div className="hidden sm:block relative w-full min-h-[300px]">
        <img
          src={truckBackground}
          alt="logo"
          className="max-w-[350px] right-[-90px] top-8 sm:absolute md:max-w-[420px] md:top-1/2 md:left-1/2 md:-translate-x-1/2 md:-translate-y-1/2"
        />
      </div>
    </section>
  </div>
);

const Footer = () => {
  return (
    <footer className=" py-10 px-3 maxContainer">
      <div className="grid gap-5 justify-between grid-cols-[130px_1fr] md:grid-cols-[200px_1fr]">
        <div>
          <img src={logo} alt="logo" style={{ width: 90 }} />
          <div className=" text-black text-opacity-70 text-xs font-normal font-['Product Sans'] leading-[18px] mt-3 max-w-[18ch] sm:max-w-[25ch]">
            Your smart solution for cost-effective truck bidding and logistics
            management, all in one place.
          </div>
        </div>
        <div
          className="grid gap-4 justify-between sm:w-[400px] lg:w-[450px] m-auto "
          style={{ gridTemplateColumns: "repeat(2, 1fr)" }}
        >
          <div>
            <p className="text-black text-opacity-70 text-xs font-medium mb-3">
              Product
            </p>
            <div className="flex flex-col gap-1">
              <div className="text-black text-opacity-50 text-xs">
                <a href="#howItWorks">How it works</a>
              </div>
              <div className="text-black text-opacity-50 text-xs">
                <a href="#interface">Interface</a>
              </div>
              <div className="text-black text-opacity-50 text-xs">
                <a href="#benefits">Benefits</a>
              </div>
              <div className="text-black text-opacity-50 text-xs">
                <a href="#insights">Insights</a>
              </div>
              <a href={zohoFormUrl} target="_blank" rel="noreferrer">
                <div className="text-black text-opacity-50 text-xs">
                  Book a Demo
                </div>
              </a>
            </div>
          </div>
          <div>
            <p className="text-black text-opacity-70 text-xs font-medium mb-3">
              Contact
            </p>
            <div className="flex flex-col gap-1">
              <div className="text-black text-opacity-50 text-xs">
                <a href="mailto: info@eazy.bid">Email Us | info@eazy.bid</a>
              </div>
              <div className="text-black text-opacity-50 text-xs">
                <a href="tel:+918956255377">Call Us | +91 8956255377 </a>
              </div>
              <div className="text-black text-opacity-50 text-xs">
                <a href="https://source.one/" target="_blank" rel="noreferrer">
                  Source.One
                </a>
              </div>
              {/* <div className="text-black text-opacity-50 text-xs">
                Privacy & Terms
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between items-end mt-10">
        <div>
          <p className="text-black text-opacity-70 text-xs mb-2">
            Brought to you by
          </p>
          <img src={sourceOneLogo} alt="logo" />
        </div>
        <div>
          <p className="text-black text-opacity-50 text-xs">
            © 2023 SPCX Private Limited
          </p>
        </div>
      </div>
    </footer>
  );
};

export default App;
